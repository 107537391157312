@import "./global.scss";

.ServiceAdvisorView {
  display: flex;
  flex-direction: column;
  color: $textSecondary;
  margin-bottom: 48px;

  .flexColumn {
    display: flex;
    flex-direction: column;
  }

  .advisorContainer {
    display: flex;
    flex-direction: row;
    margin-bottom: 24px;
  }

  .advisorAvatar {
    margin-right: 8px;
    width: 48px;
    height: 48px;
    border-radius: 24px;
    background-color: #d8d8d8;
  }

  .advisorHeader {
    font: bold 15px $brandFontFamily;
    margin-bottom: 10px;
    color: $textPrimary;
  }

  .advisorName {
    font-size: 18px;
    margin-bottom: 4px;
  }

  .advisorNumber {
    font-size: 14px;
    color: $textSecondary;
  }

  .contactButtonContainer {
    display: flex;
    flex-direction: row;
    .callButton {
      margin-right: 24px;
    }
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .ServiceAdvisorView .advisorContainer {
    flex-direction: column;
    align-items: center;
  }

  .ServiceAdvisorView .advisorContainer .advisorAvatar {
    margin-right: 0px;
    margin-bottom: 4px;
  }

  .ServiceAdvisorView .contactButtonContainer {
    flex-direction: column;
    & .callButton {
      margin-right: 0px;
      margin-bottom: 20px;
    }
  }
}
