@import "./global.scss";

.DealerView {
  display: flex;
  flex-direction: column;

  .name {
    font: bold 15px $brandFontFamily;
    color: $textPrimary;
    margin-bottom: 12px;
  }
  .address {
    color: $textSecondary;
    font-size: 14px;
    margin-bottom: 24px;
  }

  .directionsButtonContainer {
    display: flex;
    flex-direction: row; // "row" will cause the button to wrap to its contents
  }
}

@media screen and (max-width: $mobileMaxWidth) {
  .DealerView .directionsButtonContainer {
    flex-direction: column; // "column" will make the button fill its contents
  }
}
